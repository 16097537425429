<template>
  <div class="account-manage">
    <!-- 查询表单 -->
    <base-form
      :componentList="formConfig"
      :formAttrs="{
        model: queryParas,
        labelWidth: '90px'
      }"
      class="formStyle"
      @handleFilter="handleFilter"
      @clearParams="clearParams"
      ref="formData">
    </base-form>
    <!-- 表格 -->
    <base-table
    class="main-page-table"
      :columns="columns"
      :showPage="true"
      :tableAttrs="{
        stripe: true,
      }"
      :queryParas="queryParas"
      :dataSource.sync="tableData"
      ref="tableData"
      :api="api"
      :getApi="'account'"
      :loadCount="loadCount"
      :webPage="false"
      >
      <template slot="index" slot-scope="scope">{{scope.$index + 1}}</template>
      <!-- 表内回函文件 -->
      <template slot="fileName" slot-scope="scope">
        <template v-if="scope.row.fileName">
          <div class="file-content" ref='fileName'>
            <!-- 文件名 -->
            <el-tooltip  effect="dark" :content="scope.row.fileName" placement="top-end" :disabled="disabledar[scope.$index]" :enterable="false">
            <span  class="file-name  hiddentip" @click="downloadFile(scope.row.fileId)"  ref="hiddentip" >{{scope.row.fileName}}</span>
            </el-tooltip>
            <!-- 删除文件 -->
            <span class="remove-file">
              <i class="iconfont iconshanchu2 " @click="accountFileEdit({},scope.row,'delete')" v-if="scope.row.lockFlag === 0&&scope.row.statusName !== '已还款'"/>
              <base-button type="text" label="" icon="iconfont iconshangchuan" btnType="upload" :http-request=" function (url) {return UploadFile(url, scope.row)}" />
            </span>
          </div>
        </template>
        <!-- 上传按钮 -->
        <el-tooltip  v-else content='上传' effect="dark"  placement="top" :enterable="false">
        <base-button type="text" label="" icon="iconfont iconshangchuan" btnType="upload" :http-request=" function (url) {return UploadFile(url, scope.row)}"
                    accept=".doc,.docx,.xlsx,.xls,.pdf,.png,.jpg,.gif,.bmp"></base-button>
        </el-tooltip>
      </template>
      <!-- 表内操作 -->
      <template slot="action" slot-scope="scope">
        <icon-button @click="accountUnlock(scope.row)" v-if="scope.row.lockFlag === 1" content="解锁" icon="iconfont iconjiesuo"/>
        <icon-button @click="accountLock(scope.row)" v-else-if="scope.row.lockFlag === 0 && scope.row.fileName&&scope.row.statusName !== '已还款'" content="加锁" icon="iconfont iconjiasuo"/>
        <icon-button  content="查看" icon="iconfont iconchakan" @click="viewDetail(scope.row)"/>
      </template>
    </base-table>
    <!-- 详情弹出框 -->
    <manage-details :visible.sync="visibleDetail"  :finResultId="finResultId" :businessId='businessId' :detailInfo='detailInfo'/>
  </div>
</template>
<script>
import IconButton from '@/components/common/button/icon-button/icon-button'
import BaseForm from '@/components/common/base-form/base-form.vue'
import { dataForm, dataTable } from './utils/config'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import { riskAPi } from '@/api/riskApi'
import { supplierApi } from '@/api/companyApi'
import { fileAPi } from '@/api/fileApi'
import { downFile } from '@/utils/devUtils'
import ManageDetails from './components/manage-details.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import { getDictLists } from '@/filters/fromDict'

export default {
  name: 'accountManage',
  components: { BaseForm, BaseTable, IconButton, ManageDetails, BaseButton },
  data () {
    return {
      queryParas: {
        pageSize: 10,
        pageIndex: 1
      },
      loadCount: 0,
      tableData: [],
      gysData: [],
      visibleDetail: false,
      detailInfo: {},
      // showBtn: true,
      finResultId: '',
      businessId: '',
      disabledar: [],
      fileName: ''
    }
  },
  computed: {
    formConfig () {
      return dataForm(this)
    },
    columns () {
      return dataTable(this)
    },
    api () {
      return riskAPi
    },
    // 加锁解锁状态
    lockOptions () {
      return getDictLists('LOCK_FLAG')
    }
  },
  mounted () {
    this.handleFilter()
    this.gysList()
  },
  methods: {
    // 获取供应商数据
    gysList () {
      supplierApi.getList().then(res => {
        if (res.success) {
          this.gysData = res.data
        }
      })
        .catch(error => {
          console.log(error)
        })
    },
    handleFilter () {
      this.queryParas.pageIndex = 1
      this.loadCount++
    },
    clearParams () {
      this.queryParas = {
        pageIndex: 1,
        pageSize: 10
      }
    },
    UploadFile (param, row) {
      this.fileName = JSON.parse(JSON.stringify(param.file.name))
      if (this.fileName.length > 80) {
        return this.warning('文件名称小于80字符')
      }
      const EmojiReg = /[^\u0020-\u007E\u00A0-\u00BE\u2E80-\uA4CF\uF900-\uFAFF\uFE30-\uFE4F\uFF00-\uFFEF\u0080-\u009F\u2000-\u201f\u2026\u2022\u20ac\r\n]/g
      if (EmojiReg.test(param.file.name)) {
        this.warning('文件名不能存在Emoji')
        return false
      }
      const maxSize = 1024 * 10
      const fileSize = Math.round(param.file.size / 1024)
      if (fileSize - maxSize >= 0) {
        return this.warning('文件需小于10M')
      }

      const formData = new FormData()
      formData.append('file', param.file)
      formData.append('belongCode', 'TEMPLATE')
      fileAPi.file(formData).then(res => {
        if (res.success) {
          this.accountFileEdit(res.data, row)
        }
      })
    },
    // 删除文件上传
    accountFileEdit (file, row, type) {
      const params = {
        businessNo: row.businessNo,
        fileId: file.keyId ? file.keyId : '',
        fileName: file.fileName ? file.fileName : '',
        keyId: row.keyId,
        lockFlag: row.lockFlag
      }
      if (type === 'delete') {
        this.$stateConfirm({ title: '提示', message: '此操作将删除回函文件是否继续？', show: true, type: 'warning' }).then(() => {
          this.api.accountFileEdit(params).then(res => {
            if (res.success) {
              this.success('删除成功')
              this.handleFilter()
            }
          })
        }).catch(() => {})
      } else {
        this.api.accountFileEdit(params).then(res => {
          if (res.success) {
            this.success('上传成功')
            this.handleFilter()
          }
        })
      }
    },
    // 解锁
    accountUnlock (row) {
      // if (row.statusName === '已还款') {
      //   this.showBtn = false
      // }
      this.$stateConfirm({ title: '提示', message: '此业务已锁定是否解锁？', show: true, type: 'warning' }).then(() => {
        const params = {
          accountNo: row.accountNo,
          businessId: row.businessId,
          fileId: row.fileId,
          finResultId: row.finResultId,
          keyId: row.keyId,
          lockFlag: 0
        }
        this.api.accountUnlock(params).then(res => {
          if (res.success) {
            this.success('解锁成功')
            this.handleFilter()
          }
        })
      }).catch(() => {})
    },
    // 加锁
    accountLock (row) {
      this.$stateConfirm({ title: '提示', message: '此操作将加锁该数据是否继续？', show: true, type: 'warning' }).then(() => {
        const params = {
          accountNo: row.accountNo,
          businessId: row.businessId,
          fileId: row.fileId,
          finResultId: row.finResultId,
          keyId: row.keyId,
          lockFlag: 1
        }
        this.api.accountLock(params).then(res => {
          if (res.success) {
            this.success('加锁成功')
            this.handleFilter()
          }
        })
      }).catch(() => {})
    },
    // 回函文件下载
    downloadFile (id) {
      fileAPi.download({ keyId: id }).then(res => {
        downFile(res)
      })
    },
    // 查看详情
    viewDetail (data) {
      this.detailInfo = data
      this.visibleDetail = true
      this.businessId = data.businessId
      this.finResultId = data.finResultId
    }
  }
}
</script>
<style lang="scss" src="./index.scss" scoped/>
