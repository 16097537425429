import BaseSelect from '@/components/common/base-select/base-select.vue'

// 查询form
export const dataForm = (content) => {
  return [
    {
      label: '业务编号',
      prop: 'businessNo',
      attrs: {
        placeholder: '请输入',
        maxlength: 20
      }
    },
    {
      label: '供应商',
      prop: 'gysId',
      rules: [{ required: false, message: '请输入', trigger: 'blur' }],
      attrs: {
        placeholder: '请选择',
        options: content.gysData,
        filterable: true,
        selectedField: ['keyId', 'enterpriseName']
      },
      tag: BaseSelect
    },
    {
      label: '是否已锁定',
      prop: 'lockFlag',
      attrs: {
        options: content.lockOptions,
        placeholder: '请选择',
        selectedField: ['dictId', 'dictName']
      },
      tag: BaseSelect
    }
  ]
}
// 表格form
export const dataTable = (content) => {
  return [
    {
      label: '序号',
      prop: 'index',
      width: '80px'
    },
    {
      label: '业务编号',
      prop: 'businessNo',
      minWidth: '150px'
    },
    {
      label: '融资状态',
      prop: 'statusName',
      minWidth: '150px'
    },
    {
      label: '供应商',
      prop: 'gysCompanyName',
      minWidth: '150px',
      align: 'left',
      'show-overflow-tooltip': true
    },
    {
      label: '收款人名称',
      prop: 'account',
      minWidth: '150px',
      align: 'left',
      'show-overflow-tooltip': true
    },
    {
      label: '收款人开户银行',
      prop: 'bankName',
      minWidth: '150px',
      align: 'left',
      'show-overflow-tooltip': true
    },
    {
      label: '收款人银行账号',
      prop: 'accountNo',
      minWidth: '150px',
      align: 'left',
      'show-overflow-tooltip': true
    },
    {
      label: '回函文件',
      prop: 'fileName',
      minWidth: '150px',
      HiddenOverflow: true
    },
    {
      label: '账号是否已锁定',
      prop: 'lockFlag',
      minWidth: '150px',
      formatter: row => {
        const [obj] = content.lockOptions.filter(item => item.dictId === row.lockFlag.toString())
        return obj ? obj.dictName : ''
      }
    },
    {
      label: '操作',
      prop: 'action',
      fixed: 'right',
      minWidth: '150px'
    }
  ]
}
